import { render, staticRenderFns } from "./Services.vue?vue&type=template&id=881236c4&scoped=true&"
import script from "./Services.vue?vue&type=script&lang=js&"
export * from "./Services.vue?vue&type=script&lang=js&"
import style0 from "./Services.vue?vue&type=style&index=0&id=881236c4&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "881236c4",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {UIBaseCardContent: require('C:/home/site/wwwroot/components/UI/BaseCardContent.vue').default,UIBaseCard: require('C:/home/site/wwwroot/components/UI/BaseCard.vue').default})
