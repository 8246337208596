import { render, staticRenderFns } from "./OurPromise.vue?vue&type=template&id=5d1a4587&scoped=true&"
import script from "./OurPromise.vue?vue&type=script&lang=js&"
export * from "./OurPromise.vue?vue&type=script&lang=js&"
import style0 from "./OurPromise.vue?vue&type=style&index=0&id=5d1a4587&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5d1a4587",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {UIBaseCardContent: require('C:/home/site/wwwroot/components/UI/BaseCardContent.vue').default,UIBaseCard: require('C:/home/site/wwwroot/components/UI/BaseCard.vue').default})
