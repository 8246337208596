import { render, staticRenderFns } from "./index.vue?vue&type=template&id=112a31bd&scoped=true&"
import script from "./index.vue?vue&type=script&defer=true&lang=js&"
export * from "./index.vue?vue&type=script&defer=true&lang=js&"
import style0 from "./index.vue?vue&type=style&index=0&id=112a31bd&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "112a31bd",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {HomepageHero: require('C:/home/site/wwwroot/components/homepage/Hero.vue').default,HomepageServices: require('C:/home/site/wwwroot/components/homepage/Services.vue').default,HomepageWhatWantToLearn: require('C:/home/site/wwwroot/components/homepage/WhatWantToLearn.vue').default,HomepageOurPromise: require('C:/home/site/wwwroot/components/homepage/OurPromise.vue').default,HomepageOurClients: require('C:/home/site/wwwroot/components/homepage/OurClients.vue').default,UIFreeResources: require('C:/home/site/wwwroot/components/UI/FreeResources.vue').default})
