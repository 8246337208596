
export default {
  data() {
    return {
      structuredData: {
        '@context': 'http://schema.org',
        '@type': 'Organization',
        name: 'The Great Canadian Training & Consulting Company Inc.',
        url: 'https://greatcanadiantraining.ca/',
        logo: 'https://greatcanadiantraining.ca/great_canadian_training.webp',
        slogan: 'We are your guide to greater success.',
        sameAs: [
          'https://www.facebook.com/gctcompuease/',
          'https://www.youtube.com/channel/UCamr3aFjNKLHWbiWteHhU_w',
          'https://twitter.com/GCT_Compuease',
          'https://www.instagram.com/greatcanadiantraining/',
          'https://www.linkedin.com/company/great-canadian-training-compuease/?originalSubdomain=ca',
        ],
        description:
          'Software and Professional Skills Training Company in Canada',
        email: 'info@greatcanadiantraining.ca',
        address: {
          '@type': 'PostalAddress',
          streetAddress: '133 Brookhouse Drive',
          addressLocality: 'Newcastle',
          addressRegion: 'Ontario',
          postalCode: 'L1B 1N9',
          addressCountry: 'CA',
        },
        contactPoint: {
          '@type': 'ContactPoint',
          telephone: '+1-833-209-2624',
          contactType: 'Customer Service',
          availableLanguage: ['English', 'French'],
          contactOption: 'TollFree',
        },
      },
    }
  },
  head() {
    return {
      script: [
        { type: 'application/ld+json', json: this.structuredData },
        {
          src: '/bingAdds.js',
          body: true,
          defer: true,
        },
      ],
      link: [
        {
          hid: 'canonical',
          rel: 'canonical',
          href: `https://greatcanadiantraining.ca`,
        },
      ],
    }
  },
  computed: {
    siteMapItemsFromStore() {
      return this.$store.getters.getSiteMapItemsFromStore
    },
  },
  beforeMount() {
    if (localStorage.getItem('gct-fr')) {
      this.$router.push('/fr')
    }
  },
  mounted() {
    if (this.$route.query.newsletter) {
      setTimeout(() => {
        this.scroll()
      }, 250)
    }
  },
  methods: {
    scroll() {
      document.getElementById('newsletter').scrollIntoView({
        block: 'start',
      })
    },
  },
}
